.theme-submenu.ant-dropdown-menu,
#dark .theme-submenu.ant-dropdown-menu {
    border: 0;
    box-shadow: none;

    padding: 0;
    margin: 0;
}
.theme-submenu.ant-dropdown-menu .ant-dropdown-menu {
    padding: 0;
    margin: 0;
}
.theme-submenu.ant-dropdown-menu .ant-dropdown-menu-title-content {
    display: flex;
}
